import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import { cacheDataProviderProxy } from 'react-admin'; 

// import simpleRestProvider from 'ra-data-simple-rest';

// const fetchJson = (url, options = {}) => {
//     options.user = {
//         authenticated: true,
//         token: localStorage.getItem('token')
//     };
//     return fetchUtils.fetchJson(url, options);
// };


const apiUrl = 'https://percko-waviigroupsservice.getwavii.co/group/admin';
// const apiUrl = 'http://localhost:6000/group/admin';


const fetchJson = (url, options = {}) => {
    options.user = {
        authenticated: true,
        token: localStorage.getItem('token')
    };
    return fetchUtils.fetchJson(url, options);
};

const httpClient = fetchJson;

//export default 
const dataProvider = {
    getList: (resource, params) => {
        console.log("getList", resource, params);
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            console.log(json.data);
            return ({
                data: json.data,
                total: json.total,
            })
        });
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json.data,
        })),

    getMany: (resource, params) => {
        console.log("getMany", resource, params);

        let target_array = [];
        params.ids.map((a) => {
            target_array.push({"$oid": a});
        })

        const query = {
            //filter: JSON.stringify({ id: params.ids }),
            filter: JSON.stringify({
                _id: {
                    "$in": target_array,
                },
            }),
        };
        console.log(query.filter);
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => {
            console.log(json.data);
            return ({ data: json.data });
        });
    },

    getManyReference: (resource, params) => {
        console.log("getManyReference", resource, params);
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        let target_array = [];

        if (Array.isArray(params.id)) {
            params.id.map((a) => {
                target_array.push({"$oid": a});
            })
        } else {
            target_array.push({"$oid": params.id});
        }

        console.log(target_array);
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: {
                    "$in": target_array,
                },
            }),
        };
        console.log(query.filter);
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.data,
            total: json.total,
        }));
    },

    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json })),

    updateMany: (resource, params) => {
        console.log("updateMany");
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        })),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {

        let target_array = [];
        params.ids.map((a) => {
            target_array.push({"$oid": a});
        })

        const query = {
            //filter: JSON.stringify({ id: params.ids }),
            filter: JSON.stringify({
                _id: {
                    "$in": target_array,
                },
            }),
        };

        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },
};

export default cacheDataProviderProxy(dataProvider);


// import fakeRestProvider from 'ra-data-fakerest';
// import { cacheDataProviderProxy } from 'react-admin';

// import data from './fake-data';
// import addUploadFeature from './addUploadFeature';

// const dataProvider = fakeRestProvider(data, true);
// const uploadCapableDataProvider = addUploadFeature(dataProvider);
// const sometimesFailsDataProvider = new Proxy(uploadCapableDataProvider, {
//     get: (target, name) => (resource, params) => {
//         // add rejection by type or resource here for tests, e.g.
//         // if (name === 'delete' && resource === 'posts') {
//         //     return Promise.reject(new Error('deletion error'));
//         // }
//         if (
//             resource === 'posts' &&
//             params.data &&
//             params.data.title === 'f00bar'
//         ) {
//             return Promise.reject(new Error('this title cannot be used'));
//         }
//         return uploadCapableDataProvider[name](resource, params);
//     },
// });
// const delayedDataProvider = new Proxy(sometimesFailsDataProvider, {
//     get: (target, name) => (resource, params) =>
//         new Promise(resolve =>
//             setTimeout(
//                 () =>
//                     resolve(sometimesFailsDataProvider[name](resource, params)),
//                 3000
//             )
//         ),
// });

// export default cacheDataProviderProxy(delayedDataProvider);
