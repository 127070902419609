import React from 'react';
import { 
	Edit,
	SimpleForm,
	TextField,
} from 'react-admin';
import { MemberReferenceField } from '../members/MemberReferenceField';

import { ColorInput } from 'react-admin-color-input';
import { MemberChecklist } from '../members/MemberChecklist';

export const TeamEdit = props => (
	<Edit {...props}>
		<SimpleForm>
			<TextField source="id" />
			<TextField source="name" />
			<ColorInput source="color" />
			<MemberReferenceField source="members" />
			<MemberChecklist source="members"/>
		</SimpleForm>
	</Edit>
);

export default TeamEdit;