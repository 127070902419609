import React, {Component} from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser'; 

const MessageComponent = styled.div`
	margin: 20px;
	max-width: 300px;
`
const MessageDate = styled.div`
	font-size: 10px;
`
const MessageAuthor = styled.div`
	font-size: 10px;
`
const MessageContent = styled.div`
	padding: 15px 20px;
	background-color: #d2d4d7;
    font-size: 20px;
    font-weight: 200;
    border-radius: 10px;
`

export default class MessagePreview extends Component {
	render() {
		console.log(this.props.record)
		return(
			<MessageComponent>

				<MessageAuthor>
					{ this.props.record.author }
				</MessageAuthor>
				<MessageContent>
					{ ReactHtmlParser(this.props.record.content) }
				</MessageContent>
			</MessageComponent>
		)
	}
}

