import React from 'react';
import { Create, SimpleForm, TextInput, TextField, SelectInput } from 'react-admin';
import { DateTimeInput } from 'react-admin-date-inputs';
import { Box } from '@material-ui/core';

import RichTextInput from 'ra-input-rich-text';
import MessagePreview from './MessagePreview';

const choices = [
    { status: 'draft', name: 'Draft'},
    { status: 'published', name: 'Published'},
];


const dateFormatter = v => {
   console.log(v);
  // v is a `Date` object
  if (!(v instanceof Date) || isNaN(v)) return;
  const pad = '00';
  const yy = v.getFullYear().toString();
  const mm = (v.getMonth() + 1).toString();
  const dd = v.getDate().toString();
  return `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`;
};

const dateParser = v => {
  console.log(v);
  // v is a string of "YYYY-MM-DD" format
  const match = /(\d{4})-(\d{2})-(\d{2})/.exec(v);
  if (match === null) return;
  const d = new Date(match[1], parseInt(match[2], 10) - 1, match[3]);
  if (isNaN(d)) return;
  return d;
};

export const MessageCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextField source="id" />
            <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                <DateTimeInput 
                        onChange={date => console.log("date: ", date)}
                        showTodayButton
                        source="publicationdate"
                        label="publication date"
                    //providerOptions={{ utils: MomentUtils, moment: moment, locale: "fr" }}
                    //options={{ format: 'DD/MM/YYYY, HH:mm:ss', ampm: false}}
                    />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <SelectInput source="messagestatus" choices={choices} optionText="name" optionValue="status" />
                </Box>
            </Box>
            <TextInput source="author" />
            <RichTextInput source="content"/>
            <MessagePreview />
        </SimpleForm>
    </Create>
);

export default MessageCreate;