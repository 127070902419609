import MessageIcon from '@material-ui/icons/Message';
import MessageList from './MessageList';
import MessageEdit from './MessageEdit';
import MessageCreate from './MessageCreate';

export default {
    list: MessageList,
    icon: MessageIcon,
    edit: MessageEdit,
    create: MessageCreate,
};