// in src/authProvider.js

const loginUrl = 'https://percko-waviigroupsservice.getwavii.co/group/admin/login';
// const loginUrl = 'http://localhost:6000/group/admin/login';


export default {
    // called when the user attempts to log in
    login: ({ username, password }) =>  {
        let loginData = {
            data: {
                email: username,
                password: password,
                groupid: '5e176c92df11f021c8e72951'
            }
        }
        const request = new Request(loginUrl, {
            method: 'POST',
            body: JSON.stringify(loginData),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        console.log(request);
        return fetch(request)
            .then(response => {
                console.log(response);
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((loginResponse) => {
                localStorage.setItem('token', loginResponse.data.token);
            });
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};