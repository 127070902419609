import MemberIcon from '@material-ui/icons/Person';

import MemberList from './MemberList';
import MemberEdit from './MemberEdit';

export default {
    list: MemberList,
    edit: MemberEdit,
    icon: MemberIcon,
    show: MemberEdit,
};
