// in src/joinrequests.js
import React from 'react';
import { List, Datagrid, TextField, EmailField, DateField } from 'react-admin';

import { AcceptJoinRequestButton, DismissJoinRequestButton } from './JoinRequestButtons'

import { JoinRequestAvatar } from './JoinRequestAvatar'

export const JoinRequestList = props => (
    <List title="Join requests list" {...props}>
        <Datagrid rowClick="edit">
            <JoinRequestAvatar />
        	<EmailField source="email" />
            <TextField source="publicname" />
            <DateField source="createdat" />
            <TextField source="id" />
            <AcceptJoinRequestButton />
            <DismissJoinRequestButton />
        </Datagrid>
    </List>
);

export default JoinRequestList;
