import React from 'react';
import { Edit, SimpleForm, TextInput, TextField, DateField, SelectInput } from 'react-admin';

import MomentUtils from '@date-io/moment';
//import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr";
import { DateTimeInput } from 'react-admin-date-inputs';

import { Box } from '@material-ui/core';

import RichTextInput from 'ra-input-rich-text';
import MessagePreview from './MessagePreview';
import moment from "moment";
import "moment/locale/fr";

moment.locale("fr");

const choices = [
    { status: 'draft', name: 'Draft'},
    { status: 'published', name: 'Published'},
];

const dateFormatter = v => {
   console.log(v);
  // v is a `Date` object
  if (!(v instanceof Date) || isNaN(v)) return;
  return moment(v).format("DD/MM/YYY");
};

const dateParser = v => {
  console.log(moment(v));
  return moment(v).toDate();
};

export const MessageEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="id" />
            <DateTimeInput 
            onChange={date => console.log("date: ", date)}
            showTodayButton
            source="publicationdate"
            label="publication date"
            //providerOptions={{ utils: MomentUtils, moment: moment, locale: "fr" }}
            //options={{ format: 'DD/MM/YYYY, HH:mm:ss', ampm: false}}
            />
            <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                    <DateField source="createdat" parse={dateParser} />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                    
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <SelectInput source="messagestatus" choices={choices} optionText="name" optionValue="status" />
                </Box>
            </Box>
            <TextInput source="author" />
            <RichTextInput source="content"/>
            <MessagePreview />
        </SimpleForm>
    </Edit>
);

export default MessageEdit;