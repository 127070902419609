import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import AppearanceIcon from '@material-ui/icons/ColorLens';
import { useMediaQuery, Theme } from '@material-ui/core';
import { useTranslate, DashboardMenuItem, MenuItemLink } from 'react-admin';

import members from '../members';
import teams from '../teams';
import joinRequests from '../joinRequests';
import messages from '../messages';
import emails from '../emails';

import SubMenu from './SubMenu';
import { AppState } from '../types';

type MenuName = 'menuUsers' | 'menuConfiguration';


interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const [state, setState] = useState({
        menuUsers: false,
        menuConfiguration: false,
    });
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <div>
            {' '}
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
            <SubMenu
                handleToggle={() => handleToggle('menuUsers')}
                isOpen={state.menuUsers}
                sidebarIsOpen={open}
                name="pos.menu.users"
                icon={<members.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/members`}
                    primaryText={translate(`resources.members.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<members.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/joinrequests`}
                    primaryText={translate(`resources.joinrequests.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<joinRequests.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/teams`}
                    primaryText={translate(`resources.teams.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<teams.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            <MenuItemLink
                to={`/messages`}
                primaryText={translate(`resources.messages.name`, {
                    smart_count: 2,
                })}
                leftIcon={<messages.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />

            <SubMenu
                handleToggle={() => handleToggle('menuConfiguration')}
                isOpen={state.menuConfiguration}
                sidebarIsOpen={open}
                name="pos.menu.configuration"
                icon={<SettingsIcon/>}
                dense={dense}
            >
                <MenuItemLink
                    to="/configuration"
                    primaryText={translate('pos.appearance')}
                    leftIcon={<AppearanceIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to="/contest"
                    primaryText={translate('pos.contest.name')}
                    leftIcon={<SettingsIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to="/emails"
                    primaryText={translate('pos.emails')}
                    leftIcon={<emails.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            {isXSmall && logout}
        </div>
    );
};

export default Menu;
