// in ./JoinRequestButtons.js

import React from 'react';
import { useMutation, useNotify, useRefresh, Button } from 'react-admin';


export const AcceptJoinRequestButton = ({ record }) => {
    const notify = useNotify();
    const refresh = useRefresh();

    const [approve, { loading }] = useMutation(
        {
            type: 'update',
            resource: 'joinrequests',
            payload: { id: record.id, data: { isApproved: true } },
        },
        {
            onSuccess: ({ data }) => {
                refresh();
                notify('Member approved');
            },
            onFailure: (error) => notify(`Member approval error: ${error.message}`, 'warning'),
        }
    );
    return <Button color="primary" label="Approve" onClick={approve} disabled={loading} />;
};


export const DismissJoinRequestButton = ({ record }) => {
    const notify = useNotify();
    // const refresh = useRefresh();

    const [dismiss, { loading }] = useMutation(
        {
            type: 'delete',
            resource: 'joinrequests',
            payload: { id: record.id, data: { isApproved: false } },
        },
        {
            onSuccess: ({ data }) => {
                notify('Member deleted');
            },
            onFailure: (error) => notify(`Member approval error: ${error.message}`, 'warning'),
        }
    );
    return <Button color="secondary" label="Dismiss" onClick={dismiss} disabled={loading} />;
};

export default AcceptJoinRequestButton;
