import React from 'react';
import { Filter, TextInput, List, Datagrid, TextField, EmailField, DateField } from 'react-admin';

import { MemberAvatar } from './MemberAvatar';
import TeamReferenceField from '../teams/TeamReferenceField';

// const MemberFilter = (props) => (
//     <Filter {...props}>
//         <TextInput label="Search" source="publicname" alwaysOn />
//     </Filter>
// );

export const MemberList = props => (
    <List title="Group members list" {...props}>
        <Datagrid rowClick="edit">
            <MemberAvatar />
            <EmailField source="email" label="Email"/>
            <TextField source="publicname" label="Pseudo"/>
            <TextField source="id" />
            <DateField source="lastsession" showTime />
            <TeamReferenceField/>
        </Datagrid>
    </List>
);

export default MemberList;