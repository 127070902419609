import React from 'react';
import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import './App.css';

import authProvider from './authProvider';
import themeReducer from './themeReducer';
import { Login, Layout } from './layout';
import { Dashboard } from './dashboard';
import customRoutes from './routes';
import englishMessages from './i18n/en';

import members from './members';
import teams from './teams';
import emails from './emails';
import joinRequests from './joinRequests';

import messages from './messages';

import  dataProvider  from './dataProvider'

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }
    // Always fallback on english
    return englishMessages;
}, 'en');

const App = () => {

    return (
        <Admin
            title="Wavii group admin"
            dataProvider={dataProvider}
            customReducers={{ theme: themeReducer }}
            customRoutes={customRoutes}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
        >
            <Resource name="members" {...members} />
            <Resource name="teams" {...teams} />
            <Resource name="joinrequests" {...joinRequests} />
            <Resource name="messages" {...messages} />
            <Resource name="emails" {...emails} />

        </Admin>
    );
};

export default App;
