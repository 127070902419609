import React, { 
	useCallback,
	useEffect,
	useState,
} from 'react';

import {
	useDataProvider,
	useVersion,
	useInput,
} from 'react-admin';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';

export const MemberChecklist = (props) => {

	const { input } = useInput(props);

	const [state, setState] = useState({
		checked: input.value,
		groupMembers: [],
	});

	const version = useVersion();
	const dataProvider = useDataProvider();

	const fetchData = useCallback(async () => {
		const { data: groupMembers } = await dataProvider.getList('members', {
			filter: {},
			sort: { field: 'userid', order: 'DESC' },
			pagination: { page: 1, perPage: 50 },
		});

		console.log("groupMembers", groupMembers);
		
		setState(state => ({
			...state,
			groupMembers: groupMembers,
		}));

	}, [dataProvider]);

	useEffect(() => {
		fetchData();
		console.log("input", input);
		console.log("record", props.record);
	}, [version]); // eslint-disable-line react-hooks/exhaustive-deps


	const handleToggle = (value) => () => {
		const { checked } = state;
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		console.log(value);

		if (currentIndex === -1) {
		  newChecked.push(value);
		} else {
		  newChecked.splice(currentIndex, 1);
		}

		input.value = newChecked;
		input.onChange(newChecked);
		setState({
			...state,
			checked: newChecked,
		})
	};

	return(
		<div>
			<List>
			  {state.groupMembers.map((member) => {
				const labelId = `checkbox-list-label-${member.id}`;

				return (
				  <ListItem key={member.id} role={undefined} dense button onClick={handleToggle(member.id)}>
					<ListItemIcon>
					  <Checkbox
						edge="start"
						checked={state.checked.indexOf(member.id) !== -1}
						tabIndex={-1}
						disableRipple
						inputProps={{ 'aria-labelledby': labelId }}
					  />
					</ListItemIcon>
					<ListItemText id={labelId} primary={member.publicname} />
					<ListItemSecondaryAction>
					  <IconButton edge="end" aria-label="comments">
						<CommentIcon />
					  </IconButton>
					</ListItemSecondaryAction>
				  </ListItem>
				);
			  })}
			</List>
		</div>
	);
}

export default MemberChecklist;