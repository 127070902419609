import TeamIcon from '@material-ui/icons/People';

import TeamList from './TeamList';
import TeamEdit from './TeamEdit';
import TeamCreate from './TeamCreate';

export default {
    list: TeamList,
    icon: TeamIcon,
    edit: TeamEdit,
    create: TeamCreate,
};
