import React, { Component } from 'react';
import ReactEmailEditor from 'react-email-editor'
import { Button } from 'react-admin';

export class EmailEditor extends Component {

	exportHtml = () => {
		this.editor.exportHtml(data => {
			const { design, html } = data;
			console.log('exportHtml', html);
		})
	}

	onLoad = () => {
	// this.editor.addEventListener('onDesignLoad', this.onDesignLoad)
		console.log("onLoad", this);
		//console.log(this.props.record.design);
		//let design = JSON.parse(this.props.record.design);
		//this.editor.loadDesign(design);
		this.saveDesign()
	}

	saveDesign = () => {
		this.editor.saveDesign(design => {
			//console.log('saveDesign', design);
			this.props.record.design = JSON.stringify(design);
		});
		this.editor.exportHtml(data => {
			const { design, html } = data
			//console.log('exportHtml', html)
			this.props.record.template = html;
		});
	}

	render() {
		let props = this.props
		return (
			<div {...props}>
			<Button 
					label="Save design"
					onClick={this.saveDesign}
				/>
			<ReactEmailEditor
				ref={editor => this.editor = editor}
				onLoad={this.onLoad}
				{...props}
			/>
			</div>
		);
	}
}


export default EmailEditor;