import React, { Component } from 'react';
import { Edit, SimpleForm, TextInput, DateInput, Button } from 'react-admin';

import { EmailEditor } from './EmailEditor';

export class EmailEdit extends Component {

	render() {
		let props = this.props
		return (
			<Edit {...props}>
			<SimpleForm>
				<TextInput source="id" />
				<TextInput source="from" />
				<TextInput source="subject" />
				<TextInput source="template" />
				<DateInput source="lastedit" />
				<TextInput source="locale" />
				<TextInput source="type" />
				<EmailEditor/>
			</SimpleForm>
		</Edit>
		);
	}
}

// export const EmailEdit = props => (
//     <Edit {...props}>
//         <SimpleForm>
//             <TextInput source="id" />
//             <TextInput source="from" />
//             <TextInput source="subject" />
//             <TextInput source="Template" />
//             <DateInput source="lastedit" />
//             <TextInput source="locale" />
//             <TextInput source="type" />
//             <EmailEditor
// 		        ref={editor => this.editor = editor}
// 		      />
//         </SimpleForm>
//     </Edit>
// );

export default EmailEdit;