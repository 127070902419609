import englishMessages from 'ra-language-english';

const customEnglishMessages = {
    ...englishMessages,
    pos: {
        search: 'Search',
        configuration: 'Configuration',
        appearance: 'Appearance',
        emails: 'Emails',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        contest: {
            name: 'Contest',
            workingDays: {
                name: 'Working days',
                monday: 'Monday',
                tuesday: 'Tuesday',
                wednesday: 'Wednesday',
                thursday: 'Thursday',
                friday: 'Friday',
                saturday: 'Saturday',
                sunday: 'Sunday',
            },
            dates: 'Contest\'s dates',
            startDate: 'Start date',
            endDate: 'End date',
            holidays: 'Holidays',
            rules: {
                name: 'Rules',
                exercise: 'Exercise',
                inactivity: 'Inactivity',
                riskyGesture: 'Risky gesture',
                postureGrade: 'Posture Grade',
            },
        },
        dashboard: {
            members: 'Members',
            pending_reviews: 'Pending Reviews',
            new_customers: 'New Customers',
            join_requests: 'Join Requests',
            pending_orders: 'Pending Orders',
            pending_messages: 'Messages',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Welcome to Wavii Admin',
                subtitle:"Manage Wavii contest content and users from this dashboard.",
                more_info_button: 'More info',
            },
        },
        menu: {
            sales: 'Sales',
            catalog: 'Catalog',
            customers: 'Customers',
            members: 'Members',
            users: 'Users',
            configuration: 'Configuration',
        },
    },
    resources: {
        members: {
            name: 'Member |||| Members',
            fields: {
                name: 'Name',
                teamid: 'Team ID',
                email: 'Email',
                teamname: 'Team name',
            },
        },
        joinrequests: {
            name: 'Join Requests',
        },
        teams: {
            name: 'Teams',
            fields: {
                userid: 'Users',
                members: 'Members',
            },
        },
        messages: {
            name: 'Messages',
            fields: {
                author: 'Author',
                content: 'Content',
            },
        },
    },
};

export default customEnglishMessages;
