import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';

import { MemberReferenceField } from '../members/MemberReferenceField';
import { ColorField } from 'react-admin-color-input';

export const TeamList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <ColorField source="color" />
            <MemberReferenceField source="members" />
        </Datagrid>
    </List>
);

export default TeamList;