import React, {
	useState,
	useEffect,
	useCallback,
} from 'react';

import { useVersion, useDataProvider, useTranslate, Title } from 'react-admin';

import { Grid, TextField, Button } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

import { ViewState, EditingState, IntegratedEditing } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  MonthView,
  Appointments,
  Toolbar,
  DateNavigator,
  TodayButton,
  AppointmentForm,
  AppointmentTooltip,
  ConfirmationDialog,
} from '@devexpress/dx-react-scheduler-material-ui';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import moment from 'moment'


const Contest = () => {
	const translate = useTranslate();
	const [state, setState] = useState({});
	const version = useVersion();
	const dataProvider = useDataProvider();

	const fetchConfig = useCallback(async () => {
		const { data: config } = await dataProvider.getList('config', {
			filter: {},
			sort: { field: 'userid', order: 'DESC' },
			pagination: { page: 1, perPage: 50 },
		});

		let holidays = [];

		for (var i = 0; i < config.HolidaysDates.length; i++) {
			holidays.push({
				startDate: moment(config.HolidaysDates[i]).startOf('day').toISOString(),
				endDate: moment(config.HolidaysDates[i]).endOf('day').toISOString(),
				title: "férié",
			});
		}
		config.holidays = holidays;
		console.log(config);

		setState(state => ({
			...state,
			config: config,
			workingDays: config.UseDays.map((e) => e.toString()),
			ExerciseCoef: config.Rules.exercisecoef,
			RiskygestureCoef: config.Rules.riskygesturecoef,
			InactivityCoef: config.Rules.inactivitycoef,
			GradeCoef: config.Rules.gradecoef,
		}));

	}, [dataProvider]);

	useEffect(() => {
		fetchConfig();
	}, [version]); // eslint-disable-line react-hooks/exhaustive-deps

	const {
		config,
		currentDate,
		workingDays,
		ExerciseCoef,
		RiskygestureCoef,
		InactivityCoef,
		GradeCoef,
	} = state;

	const updateConfig = useCallback(async (newConfig) => {
		console.log(newConfig);
		const { data: config } = await dataProvider.update('config', {
			data: newConfig,
		});
	}, [dataProvider]);

	const onSubmit = () => {
		console.log("onSubmit");
		let config = {...state.config};
		let { ExerciseCoef, InactivityCoef, RiskygestureCoef, GradeCoef } = {...state};
		let workingDays = Object.values({...state.workingDays});
		let newConfig = config;

		if (config.holidays) {
			newConfig.HolidaysDates = config.holidays.map((e) => {
				return e.startDate;
			})
			newConfig.UseDays = workingDays.map((e) => {
				return parseInt(e);
			})
			newConfig.Rules.exercisecoef = ExerciseCoef;
			newConfig.Rules.inactivitycoef = InactivityCoef;
			newConfig.Rules.riskygesturecoef = RiskygestureCoef;
			newConfig.Rules.gradecoef = GradeCoef;
			updateConfig(newConfig);
		}
	}

	const handleDateChange = (date) => {
		console.log(date.target.value);
		var config = {...state.config};

		config.ContestDates.StartDate = new Date(date.target.value).toISOString();
		setState(state => ({
			...state,
			config: config,
		}));
		//setSelectedDate(date);
	};

	const currentDateChange = (currentDate) => { 
		setState(state => ({
			...state,
			currentDate: currentDate,
		}));
	};

	const handleWorkingDays= (event, workingDays) => {
		setState(state => ({
			...state,
			workingDays: workingDays,
		}));
		console.log("workingDays", workingDays);
	};

	const handleExerciseRule = (event) => {
		let ExerciseCoef = {...state.ExerciseCoef};
		ExerciseCoef = event.target.value;
		if (!isNaN(ExerciseCoef) || ExerciseCoef == '' || ExerciseCoef == '-') {
			console.log("ExerciseCoef", ExerciseCoef)
			setState(state => ({
				...state,
				ExerciseCoef: ExerciseCoef,
			}));
		}
	}
	const handleInactivityRule = (event) => {
		let InactivityCoef = {...state.InactivityCoef};
		InactivityCoef = event.target.value;
		if (!isNaN(InactivityCoef) || InactivityCoef == ''|| InactivityCoef == '-') {
			setState(state => ({
				...state,
				InactivityCoef: InactivityCoef,
			}));
		}
	}
	const handleRiskyGestureRule = (event) => {
		let RiskygestureCoef = {...state.RiskygestureCoef};
		RiskygestureCoef = event.target.value;
		if (!isNaN(RiskygestureCoef) || RiskygestureCoef == ''|| RiskygestureCoef == '-') {
			setState(state => ({
				...state,
				RiskygestureCoef: RiskygestureCoef,
			}));
		}
	}
	const handlePostureGradeRule = (event) => {
		let GradeCoef = {...state.GradeCoef};
		GradeCoef = event.target.value;
		if (!isNaN(GradeCoef) || GradeCoef == ''|| GradeCoef == '-') {
			setState(state => ({
				...state,
				GradeCoef: GradeCoef,
			}));
		}
	}

	return (
		<Grid container spacing={3}>
		<Grid item xs={12}>
		<Card>
			<Title title={translate('pos.contest.name')} />
			<CardContent>
				<div>
					{translate('pos.contest.dates')}
				</div>
					<Grid container justify="space-around">

						<TextField
							id="startDate"
							label={translate('pos.contest.startDate')}
							type="date"
							value={config ? new Date(config.ContestDates.StartDate).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]}
							onChange={handleDateChange}
							InputLabelProps={{
							  shrink: true,
							}}
						/>

						<TextField
							id="endDate"
							label={translate('pos.contest.endDate')}
							type="date"
							value={config ? new Date(config.ContestDates.EndDate).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]}
							onChange={handleDateChange}
							InputLabelProps={{
							  shrink: true,
							}}
						/>

					</Grid>
			</CardContent>
		</Card>
		</Grid>
		<Grid item xs={12}>
		<Card>
			<CardContent>
			<div>
				{translate('pos.contest.workingDays.name')}
			</div>
			<Grid container justify="space-around">
				<ToggleButtonGroup value={workingDays} onChange={handleWorkingDays} aria-label="text formatting">
				  <ToggleButton value="1" aria-label="monday">
						{translate('pos.contest.workingDays.monday')}
				  </ToggleButton>
				  <ToggleButton value="2" aria-label="tuesday">
						{translate('pos.contest.workingDays.tuesday')}
				  </ToggleButton>
				  <ToggleButton value="3" aria-label="wednesday">
						{translate('pos.contest.workingDays.wednesday')}
				  </ToggleButton>
				  <ToggleButton value="4" aria-label="thursday">
						{translate('pos.contest.workingDays.thursday')}
				  </ToggleButton>
				  <ToggleButton value="5" aria-label="friday">
						{translate('pos.contest.workingDays.friday')}
				  </ToggleButton>
				  <ToggleButton value="6" aria-label="saturday">
						{translate('pos.contest.workingDays.saturday')}
				  </ToggleButton>
				  <ToggleButton value="7" aria-label="sunday">
						{translate('pos.contest.workingDays.sunday')}
				  </ToggleButton>
				</ToggleButtonGroup>
			</Grid>
			</CardContent>
		</Card>
		</Grid>
		<Grid item xs={12}>
		<Card>
			<CardContent>
			<div>
				{translate('pos.contest.rules.name')}
			</div>
			<Grid container justify="space-around">
				<TextField 
					required
					id="rules-exercise"
					label={translate('pos.contest.rules.exercise')}
					variant="outlined"
					value={ExerciseCoef ? ExerciseCoef : ''}
					onChange={handleExerciseRule}
				/>
				<TextField 
					required
					id="rules-inactivity"
					label={translate('pos.contest.rules.inactivity')}
					variant="outlined"
					value={InactivityCoef ? InactivityCoef : ''}
					onChange={handleInactivityRule}
				/>
				<TextField 
					required
					id="rules-risky-gesture"
					label={translate('pos.contest.rules.riskyGesture')}
					variant="outlined"
					value={RiskygestureCoef ? RiskygestureCoef : ''}
					onChange={handleRiskyGestureRule}
				/>
				<TextField 
					required
					id="rules-posture-grade"
					label={translate('pos.contest.rules.postureGrade')}
					variant="outlined"
					value={GradeCoef ? GradeCoef : ''}
					onChange={handlePostureGradeRule}
				/>
			</Grid>
			</CardContent>
		</Card>
		</Grid>
		<Grid item xs={12}>
		<Card>
			<CardContent>
				<div>
					{translate('pos.contest.holidays')}
				</div>
				<Grid container justify="space-around">
					<TextField
						id="date"
						label="Add holidays"
						type="date"
						value={config ? new Date(config.ContestDates.StartDate).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]}
						onChange={handleDateChange}
						InputLabelProps={{
						  shrink: true,
						}}
					/>

					<Scheduler
					  data={config ? config.holidays : []}
					>
						<ViewState
						currentDate={currentDate}
						/>
						<EditingState
							//onCommitChanges={this.commitChanges}
						/>
						<IntegratedEditing />
						<MonthView />
						<Appointments />
						<AppointmentTooltip
							showOpenButton
							showDeleteButton
						/>
						<AppointmentForm />
						<Toolbar />
						<DateNavigator />
						<TodayButton />
						<Appointments />
					</Scheduler>

				</Grid>
			</CardContent>
		</Card>
		</Grid>

		<Button variant="contained" color="primary" onClick={onSubmit}>
			Save
		</Button>
		</Grid>
	);
};

export default Contest;
