import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import { MemberReferenceField } from '../members/MemberReferenceField';
import { ColorInput } from 'react-admin-color-input';
import { MemberChecklist } from '../members/MemberChecklist';


export const TeamCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <ColorInput source="color" />
            <MemberChecklist source="members"/>
        </SimpleForm>
    </Create>
);

export default TeamCreate;