import React from 'react';
import { ReferenceManyField, SingleFieldList, ChipField } from 'react-admin';

export const MemberReferenceField = props => {
	// console.log("MemberReferenceField", props)
	return(
		<ReferenceManyField reference="members" target="userid" {...props} loaded={false}>
				<SingleFieldList>
					<ChipField source="publicname" />
				</SingleFieldList>
		</ReferenceManyField>
	);
};

MemberReferenceField.defaultProps = {
	source: 'members',
	addLabel: true,
};

export default MemberReferenceField;