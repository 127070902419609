import React from 'react';
import { Filter, TextInput, List, Datagrid, TextField, DateField, TopToolbar, ChipField } from 'react-admin';
import { ImportButton } from "react-admin-import-csv";
import { CreateButton, ExportButton } from "ra-ui-materialui";

import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const useStyles = makeStyles({
  draft: { backgroundColor: '#8c8c8c' },
});

const ListActions = props => {
	const { 
		className, 
		basePath, 
		total, 
		resource, 
		currentSort, 
		filterValues, 
		exporter 
	} = props;
	return (
		<TopToolbar className={className}>
			<CreateButton basePath={basePath} />
			<ExportButton
				disabled={total === 0}
				resource={resource}
				sort={currentSort}
				filter={filterValues}
				exporter={exporter}
			/>
			<ImportButton {...props} />
		</TopToolbar>
	);
};

const MessageFilter = (props) => (
	<Filter {...props}>
		<TextInput label="Search" source="title" alwaysOn />
	</Filter>
);

const ColoredChip = props => {
	const classes = useStyles();
	const isDraft = v => v.toUpperCase() === 'DRAFT';

	return (
		<ChipField
			className={classnames({
				[classes.draft]: isDraft(props.record[props.source]),
			})}
			{...props} 
		/>
	);
}

export const MessageList = props => (
	<List filters={<MessageFilter/>} actions={<ListActions />} title="Group members list" {...props}>
		<Datagrid rowClick="edit">
			<TextField source="author"/>
			<DateField source="publicationdate" label="Publication"/>
			<ColoredChip source="messagestatus" color="primary"/>
			<DateField source="lastedit" label="Modified"/>
			<DateField source="createdat" label="Created at"/>
			<TextField source="content"/>
		</Datagrid>
	</List>
);

export default MessageList;