import React, { Component } from 'react';
import { ReferenceManyField, SingleFieldList, ChipField } from 'react-admin';

const ColoredChip = props => {
	return (
		<ChipField style={{backgroundColor: props.record[props.color]}} {...props}/>
	);
}

export class TeamReferenceField extends Component {
	render() {
		let { props } = this;
		// console.log(props);
		return(
			<ReferenceManyField reference="teams" target="teamid" source="teamid" {...props}>
			<SingleFieldList>
				<ColoredChip source="name" color="color"/>
			</SingleFieldList>
			</ReferenceManyField>
		)
	}
}


TeamReferenceField.defaultProps = {
	source: 'teamid',
	addLabel: true,
};

export default TeamReferenceField;