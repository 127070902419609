import React from 'react';
import { TextInput, TextField, EmailField, Edit, SimpleForm, DateField, ReferenceInput, AutocompleteInput} from 'react-admin';


export const MemberEdit = props => {
	console.log("MemberEdit", props);
	return (
		<Edit {...props}>
			<SimpleForm redirect="show" {...props}>
				<TextInput disabled source="id" />
				<TextField source="publicname"/>
				<EmailField source="email" />
				<DateField source="createdat" label="Created at"/>
				<DateField source="lastsession" showTime />
				<ReferenceInput source="teamid" reference="teams">
					<AutocompleteInput
						optionText={choice =>
							choice && choice.name
								? `${choice.name}`
								: ''
						}
					/>
				</ReferenceInput>
			</SimpleForm>
		</Edit>
	);
};

export default MemberEdit;