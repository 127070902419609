import React, {
    useState,
    useEffect,
    useCallback,
    FC,
    CSSProperties,
} from 'react';
import { useVersion, useDataProvider } from 'react-admin';
import { useMediaQuery, Theme } from '@material-ui/core';

import Welcome from './Welcome';
import NbNewOrders from './NbNewOrders';
import PendingOrders from './PendingOrders';
import NewCustomers from './NewCustomers';

import { Customer, Order } from '../types';

interface OrderStats {
    revenue: number;
    nbNewOrders: number;
    pendingOrders: Order[];
}

interface CustomerData {
    [key: string]: Customer;
}

interface State {
    nbNewOrders?: number;
    pendingOrders?: Order[];
    pendingOrdersCustomers?: CustomerData;
    revenue?: number;
}

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
};

const Dashboard: FC = () => {
    const [state, setState] = useState<State>({});
    const version = useVersion();
    const dataProvider = useDataProvider();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    const fetchOrders = useCallback(async () => {
        const { data: recentOrders } = await dataProvider.getList('members', {
            filter: {},
            sort: { field: 'userid', order: 'DESC' },
            pagination: { page: 1, perPage: 50 },
        });
        const aggregations = recentOrders
            .filter((order: Order) => order.status !== 'cancelled')
            .reduce(
                (stats: OrderStats, order: Order) => {
                    if (order.status !== 'cancelled') {
                        stats.revenue += order.total;
                        stats.nbNewOrders++;
                    }
                    if (order.status === 'ordered') {
                        stats.pendingOrders.push(order);
                    }
                    return stats;
                },
                {
                    revenue: 0,
                    nbNewOrders: 0,
                    pendingOrders: [],
                }
            );
        setState(state => ({
            ...state,
            nbNewOrders: aggregations.nbNewOrders,
        }));
        const { data: customers } = await dataProvider.getList('messages', {
            filter: {
                messagestatus: 'draft',
                publicationdate: {"$gte": { "$date" : { "$numberLong": Date.now().toString() } }},
            },
            sort: { field: 'userid', order: 'DESC' },
            pagination: { page: 1, perPage: 50 },
        });
        setState(state => ({
            ...state,
            pendingOrdersCustomers: customers.reduce(
                (prev: CustomerData, customer: Customer) => {
                    prev[customer.id] = customer; // eslint-disable-line no-param-reassign
                    return prev;
                },
                {}
            ),
        }));
    }, [dataProvider]);

    useEffect(() => {
        fetchOrders();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    const {
        nbNewOrders,
        pendingOrders,
        pendingOrdersCustomers,
        revenue,
    } = state;
    return isXSmall ? (
        <div>
            <div style={styles.flexColumn as CSSProperties}>
                <div style={{ marginBottom: '2em' }}>
                    <Welcome />
                </div>
                <div style={styles.flex}>
                    <NbNewOrders value={nbNewOrders} />
                </div>
                <div style={styles.singleCol}>
                    <PendingOrders
                        orders={pendingOrders}
                        customers={pendingOrdersCustomers}
                    />
                </div>
            </div>
        </div>
    ) : isSmall ? (
        <div style={styles.flexColumn as CSSProperties}>
            <div style={styles.singleCol}>
                <Welcome />
            </div>
            <div style={styles.flex}>
                <NbNewOrders value={nbNewOrders} />
            </div>
            <div style={styles.singleCol}>
                <PendingOrders
                    orders={pendingOrders}
                    customers={pendingOrdersCustomers}
                />
            </div>
        </div>
    ) : (
        <div style={styles.flex}>
            <div style={styles.leftCol}>
                <div style={styles.flex}>
                    <NbNewOrders value={nbNewOrders} />
                </div>
                <div style={styles.singleCol}>
                    <PendingOrders
                        orders={pendingOrders}
                        customers={pendingOrdersCustomers}
                    />
                </div>
                <div style={styles.singleCol}>
                    <Welcome />
                </div>
            </div>
            <div style={styles.rightCol}>
                <div style={styles.flex}>
                    <NewCustomers />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
